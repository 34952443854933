import adminApi from "services/admin/adminApi";
import { convertCompilerOptionsFromJson } from "typescript";

export const FETCH_ADMIN_ORDER_BEGIN = "FETCH_ADMIN_ORDER_BEGIN";
export const FETCH_ADMIN_ORDER_SUCCESS = "FETCH_ADMIN_ORDER_SUCCESS";
export const FETCH_ADMIN_ORDER_FILTERS_SUCCESS =
  "FETCH_ADMIN_ORDER_FILTERS_SUCCESS";
export const FETCH_ADMIN_ORDER_FAILURE = "FETCH_ADMIN_ORDER_FAILURE";
export const FETCH_ORDER_SUMMARY_BEGIN = "FETCH_ORDER_SUMMARY_BEGIN";
export const FETCH_ORDER_SUMMARY_SUCCESS = "FETCH_ORDER_SUMMARY_SUCCESS";
export const FETCH_ORDER_SUMMARY_FAILURE = "FETCH_ORDER_SUMMARY_FAILURE";

export const FETCH_ORDER_HISTORY_BEGIN = "FETCH_ORDER_HISTORY_BEGIN";
export const FETCH_ORDER_HISTORY_SUCCESS = "FETCH_ORDER_HISTORY_SUCCESS";
export const FETCH_ORDER_HISTORY_FAILURE = "FETCH_ORDER_HISTORY_FAILURE";

export const FETCH_SHIPPING_ORDER_BEGIN = "FETCH_SHIPPING_ORDER_BEGIN";
export const FETCH_SHIPPING_ORDER_SUCCESS = "FETCH_SHIPPING_ORDER_SUCCESS";
export const FETCH_SHIPPING_ORDER_FAILURE = "FETCH_SHIPPING_ORDER_FAILURE";
export const UPDATE_PARCELMATERIAL_FILTER = "UPDATE_PARCELMATERIAL_FILTER";
export const FETCH_ORDER_PRICING_BEGIN = "FETCH_ORDER_PRICING_BEGIN";
export const FETCH_ORDER_PRICING_SUCCESS = "FETCH_ORDER_PRICING_SUCCESS";
export const FETCH_ORDER_PRICING_FAILURE = "FETCH_ORDER_PRICING_FAILURE";

export const UPDATE_ORDER_PRICING_BEGIN = "UPDATE_ORDER_PRICING_BEGIN";
export const UPDATE_ORDER_PRICING_SUCCESS = "UPDATE_ORDER_PRICING_SUCCESS";
export const UPDATE_ORDER_PRICING_FAILURE = "UPDATE_ORDER_PRICING_FAILURE";

export const FETCH_ORDER_CHAT_BEGIN = "FETCH_ORDER_CHAT_BEGIN";
export const FETCH_ORDER_CHAT_SUCCESS = "FETCH_ORDER_CHAT_SUCCESS";
export const FETCH_ORDER_CHAT_FAILURE = "FETCH_ORDER_CHAT_FAILURE";
export const FETCH_ORDER_SKU_BEGIN = "FETCH_ORDER_SKU_BEGIN";
export const FETCH_ORDER_SKU_SUCCESS = "FETCH_ORDER_SKU_SUCCESS";
export const FETCH_ORDER_SKU_FAILURE = "FETCH_ORDER_SKU_FAILURE";
export const FETCH_ORDER_NOTE_BEGIN = "FETCH_ORDER_NOTE_BEGIN";
export const UPDATE_ORDER_NOTE_SUCCESS = "UPDATE_ORDER_NOTE_SUCCESS";
export const UPDATE_ORDER_NOTE_FAILURE = "UPDATE_ORDER_NOTE_FAILURE";

export const DOWNLOAD_AU_CSV_BEGIN = "DOWNLOAD_AU_CSV_BEGIN";
export const DOWNLOAD_AU_CSV_SUCCESS = "DOWNLOAD_AU_CSV_SUCCESS";
export const DOWNLOAD_AU_CSV_FAILURE = "DOWNLOAD_AU_CSV_FAILURE";

export const ORDER_BULK_UPDATE_BEGIN = "ORDER_BULK_UPDATE_BEGIN";
export const ORDER_BULK_UPDATE_SUCCESS = "ORDER_BULK_UPDATE_SUCCESS";
export const ORDER_BULK_UPDATE_FAILURE = "ORDER_BULK_UPDATE_FAILURE";

export const ORDER_WAREHOUSE_UPDATE_BEGIN = "ORDER_WAREHOUSE_UPDATE_BEGIN";
export const ORDER_WAREHOUSE_UPDATE_SUCCESS = "ORDER_WAREHOUSE_UPDATE_SUCCESS";
export const ORDER_WAREHOUSE_UPDATE_FAILURE = "ORDER_WAREHOUSE_UPDATE_FAILURE";

export const ORDER_CHAT_CREATE_BEGIN = "ORDER_CHAT_CREATE_BEGIN";
export const ORDER_CHAT_CREATE_SUCCESS = "ORDER_CHAT_CREATE_SUCCESS";
export const ORDER_CHAT_CREATE_FAILURE = "ORDER_CHAT_CREATE_FAILURE";

export const INTERNAL_COMMENT_CREATE_BEGIN = "INTERNAL_COMMENT_CREATE_BEGIN";
export const INTERNAL_COMMENT_CREATE_SUCCESS =
  "INTERNAL_COMMENT_CREATE_SUCCESS";
export const INTERNAL_COMMENT_CREATE_FAILURE =
  "INTERNAL_COMMENT_CREATE_FAILURE";

export const CLEAR_ADMIN_ORDERS_FILTER = "CLEAR_ADMIN_ORDERS_FILTER";
export const SET_ADMIN_ORDERS_FILTER = "SET_ADMIN_ORDERS_FILTER";

export const UPDATE_ORDER_SHIPMENT_BEGIN = "UPDATE_ORDER_SHIPMENT_BEGIN";
export const UPDATE_ORDER_SHIPMENT_SUCCESS = "UPDATE_ORDER_SHIPMENT_SUCCESS";
export const UPDATE_ORDER_SHIPMENT_FAILURE = "UPDATE_ORDER_SHIPMENT_FAILURE";

export const UPDATE_ORDER_SKUS_BEGIN = "UPDATE_ORDER_SKUS_BEGIN";
export const UPDATE_ORDER_SKUS_SUCCESS = "UPDATE_ORDER_SKUS_SUCCESS";
export const UPDATE_ORDER_SKUS_FAILURE = "UPDATE_ORDER_SKUS_FAILURE";

export const UPDATE_SERIAL_NUMBERS_BEGIN = "UPDATE_SERIAL_NUMBERS_BEGIN";
export const UPDATE_SERIAL_NUMBERS_SUCCESS = "UPDATE_SERIAL_NUMBERS_SUCCESS";
export const UPDATE_SERIAL_NUMBERS_FAILURE = "UPDATE_SERIAL_NUMBERS_FAILURE";

export const FETCH_ORG_ORDER_BEGIN = "FETCH_ORG_ORDER_BEGIN";
export const FETCH_ORG_ORDER_SUCCESS = "FETCH_ORG_ORDER_SUCCESS";
export const FETCH_ORG_ORDER_FAILURE = "FETCH_ORG_ORDER_FAILURE";

export const CLEAR_ADMIN_ORDERS = "CLEAR_ADMIN_ORDERS";

export const DOWNLOAD_ORDER_TMPL_BEGIN = "DOWNLOAD_ORDER_TMPL_BEGIN";
export const DOWNLOAD_ORDER_TMPL_SUCCESS = "DOWNLOAD_ORDER_TMPL_SUCCESS";
export const DOWNLOAD_ORDER_TMPL_FAILURE = "DOWNLOAD_ORDER_TMPL_FAILURE";
export const UPLOAD_ORDER_TMPL_BEGIN = "UPLOAD_ORDER_TMPL_BEGIN";
export const UPLOAD_ORDER_TMPL_SUCCESS = "UPLOAD_ORDER_TMPL_SUCCESS";
export const UPLOAD_ORDER_TMPL_FAILURE = "UPLOAD_ORDER_TMPL_FAILURE";

export const ORDER_CREATE_BEGIN = "ORDER_CREATE_BEGIN";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAILURE = "ORDER_CREATE_FAILURE";

export const SELECT_CARRIER_BEGIN = "SELECT_CARRIER_BEGIN";
export const SELECT_CARRIER_SUCCESS = "SELECT_CARRIER_SUCCESS";
export const SELECT_CARRIER_FAILURE = "SELECT_CARRIER_FAILURE";

export const CANCEL_LABEL_BEGIN = "CANCEL_LABEL_BEGIN";
export const CANCEL_LABEL_SUCCESS = "CANCEL_LABEL_SUCCESS";
export const CANCEL_LABEL_FAILURE = "CANCEL_LABEL_FAILURE";

export const REMOVE_CARRIER_BEGIN = "REMOVE_CARRIER_BEGIN";
export const REMOVE_CARRIER_SUCCESS = "REMOVE_CARRIER_SUCCESS";
export const REMOVE_CARRIER_FAILURE = "REMOVE_CARRIER_FAILURE";

export const CREATE_CUSTOM_CARRIER_BEGIN = "CREATE_CUSTOM_CARRIER_BEGIN";
export const CREATE_CUSTOM_CARRIER_SUCCESS = "CREATE_CUSTOM_CARRIER_SUCCESS";
export const CREATE_CUSTOM_CARRIER_FAILURE = "CREATE_CUSTOM_CARRIER_FAILURE";

export const VIEW_CARRIER_BEGIN = "VIEW_CARRIER_BEGIN";
export const VIEW_CARRIER_SUCCESS = "VIEW_CARRIER_SUCCESS";
export const VIEW_CARRIER_FAILURE = "VIEW_CARRIER_FAILURE";

export const PRINT_SHIPPING_LABEL_BEGIN = "PRINT_SHIPPING_LABEL_BEGIN";
export const PRINT_SHIPPING_LABEL_SUCCESS = "PRINT_SHIPPING_LABEL_SUCCESS";
export const PRINT_SHIPPING_LABEL_FAILURE = "PRINT_SHIPPING_LABEL_FAILURE";

export const UNPROCESS_ORDER_BEGIN = "UNPROCESS_ORDER_BEGIN";
export const UNPROCESS_ORDER_SUCCESS = "UNPROCESS_ORDER_SUCCESS";
export const UNPROCESS_ORDER_FAILURE = "UNPROCESS_ORDER_FAILURE";

export const GET_ORDER_PARCELS_BEGIN = "GET_ORDER_PARCELS_BEGIN";
export const GET_ORDER_PARCELS_SUCCESS = "GET_ORDER_PARCELS_SUCCESS";
export const GET_ORDER_PARCELS_FAILURE = "GET_ORDER_PARCELS_FAILURE";

export const UPDATE_ORDER_PARCELS_BEGIN = "UPDATE_ORDER_PARCELS_BEGIN";
export const UPDATE_ORDER_PARCELS_SUCCESS = "UPDATE_ORDER_PARCELS_SUCCESS";
export const UPDATE_ORDER_PARCELS_FAILURE = "UPDATE_ORDER_PARCELS_FAILURE";

export const SUGGEST_HANDLING_BEGIN = "SUGGEST_HANDLING_BEGIN";
export const SUGGEST_HANDLING_SUCCESS = "SUGGEST_HANDLING_SUCCESS";
export const SUGGEST_HANDLING_FAILURE = "SUGGEST_HANDLING_FAILURE";

export const BOOKING_ITEM_DETAIL_BEGIN = "BOOKING_ITEM_DETAIL_BEGIN";
export const BOOKING_ITEM_DETAIL_SUCCESS = "BOOKING_ITEM_DETAIL_SUCCESS";
export const BOOKING_ITEM_DETAIL_FAILURE = "BOOKING_ITEM_DETAIL_FAILURE";
export const CLEAR_TMPLS = "CLEAR_TMPLS";

export const FETCH_BATCH_DETAIL_BEGIN = "FETCH_BATCH_DETAIL_BEGIN";
export const FETCH_BATCH_DETAIL_SUCCESS = "FETCH_BATCH_DETAIL_SUCCESS";
export const FETCH_BATCH_DETAIL_FAILURE = "FETCH_BATCH_DETAIL_FAILURE";

export const FETCH_SUGGESTED_CARRIER_BEGIN = "FETCH_SUGGESTED_CARRIER_BEGIN";
export const FETCH_SUGGESTED_CARRIER_SUCCESS =
  "FETCH_SUGGESTED_CARRIER_SUCCESS";
export const FETCH_SUGGESTED_CARRIER_FAILURE =
  "FETCH_SUGGESTED_CARRIER_FAILURE";

export const ARCHIVE_COMMENT_BEGIN = "ARCHIVE_COMMENT_BEGIN";
export const ARCHIVE_COMMENT_SUCCESS = "ARCHIVE_COMMENT_SUCCESS";
export const ARCHIVE_COMMENT_FAILURE = "ARCHIVE_COMMENT_FAILURE";

export const FETCH_INBOUND_LABEL_BEGIN = "FETCH_INBOUND_LABEL_BEGIN";
export const FETCH_INBOUND_LABEL_SUCCESS = "FETCH_INBOUND_LABEL_SUCCESS";
export const FETCH_INBOUND_LABEL_FAILURE = "FETCH_INBOUND_LABEL_FAILURE";

export const FETCH_INBOUND_REPORT_BEGIN = "FETCH_INBOUND_REPORT_BEGIN";
export const FETCH_INBOUND_REPORT_SUCCESS = "FETCH_INBOUND_REPORT_SUCCESS";
export const FETCH_INBOUND_REPORT_FAILURE = "FETCH_INBOUND_REPORT_FAILURE";

export const FETCH_INBOUND_BOXES_BEGIN = "FETCH_INBOUND_BOXES_BEGIN";
export const FETCH_INBOUND_BOXES_SUCCESS = "FETCH_INBOUND_BOXES_SUCCESS";
export const FETCH_INBOUND_BOXES_FAILURE = "FETCH_INBOUND_BOXES_FAILURE";

export const PRINT_INBOUND_SHIPPING_LABEL_BEGIN =
  "PRINT_INBOUND_SHIPPING_LABEL_BEGIN";
export const PRINT_INBOUND_SHIPPING_LABEL_SUCCESS =
  "PRINT_INBOUND_SHIPPING_LABEL_SUCCESS";
export const PRINT_INBOUND_SHIPPING_LABEL_FAILURE =
  "PRINT_INBOUND_SHIPPING_LABEL_FAILURE";

export function fetchAdminOrders(param, withoutLoading) {
  return (dispatch) => {
    if (!withoutLoading) dispatch(fetchAdminOrdersBegin()); //calling this action to show loading
    return adminApi
      .fetchAdminOrders(param) // calling list of ORDERS
      .then((json) => {
        dispatch(fetchAdminOrdersSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchAdminOrdersFailure(error));
      });
  };
}

export function fetchAdminOrdersFilters(param, withoutLoading) {
  return (dispatch) => {
    if (!withoutLoading) dispatch(fetchAdminOrdersBegin()); //calling this action to show loading
    return adminApi
      .fetchAdminOrdersFilters(param) // calling list of ORDERS
      .then((json) => {
        dispatch(fetchAdminOrdersFiltersSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchAdminOrdersFailure(error));
      });
  };
}

export function fetchOrderSummary(id) {
  return (dispatch) => {
    dispatch(fetchOrderSummaryBegin()); //calling this action to show loading
    return adminApi
      .fetchOrderSummary(id) // calling list of ORDERS
      .then((json) => {
        dispatch(fetchOrderSummarySuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchOrderSummaryFailure(error));
      });
  };
}

export function fetchOrderHistory(id) {
  return (dispatch) => {
    dispatch(fetchOrderHistoryBegin()); //calling this action to show loading
    return adminApi
      .fetchOrderHistory(id) // calling list of ORDERS
      .then((json) => {
        dispatch(fetchOrderHistorySuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchOrderHistoryFailure(error));
      });
  };
}

export function fetchShippingOrder(id) {
  return (dispatch) => {
    dispatch(fetchShippingOrderBegin()); //calling this action to show loading
    return adminApi
      .fetchShippingOrder(id) // calling list of ORDERS
      .then((json) => {
        dispatch(fetchShippingOrderSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchShippingOrderFailure(error));
      });
  };
}

export function fetchOrderPricing(id) {
  return (dispatch) => {
    dispatch(fetchOrderPricingBegin());
    return adminApi
      .fetchOrderPricing(id)
      .then((json) => {
        dispatch(fetchOrderPricingSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchOrderPricingFailure(error));
      });
  };
}

export function checkOrderJobStatus(id) {
  return (dispatch) => {
    dispatch(checkOrderJobStatusBegin());
    return adminApi
      .checkJoBStatus(id)
      .then((json) => {
        dispatch(checkOrderJobStatusSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(checkOrderJobStatusFailure(error));
        return error['response']['data'];
      });
  };
}

export const checkOrderJobStatusBegin = () => ({
  type: UPDATE_ORDER_PRICING_BEGIN,
});
export const checkOrderJobStatusSuccess = (json) => ({
  type: UPDATE_ORDER_PRICING_SUCCESS,
  data: json.data,
});
export const checkOrderJobStatusFailure = (error) => ({
  type: UPDATE_ORDER_PRICING_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function updateOrderPricing(id, data) {
  return (dispatch) => {
    dispatch(updateOrderPricingBegin());
    return adminApi
      .updateOrderPricing(id, data)
      .then((json) => {
        dispatch(updateOrderPricingSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateOrderPricingFailure(error));
      });
  };
}



export const updateOrderPricingBegin = () => ({
  type: UPDATE_ORDER_PRICING_BEGIN,
});
export const updateOrderPricingSuccess = (json) => ({
  type: UPDATE_ORDER_PRICING_SUCCESS,
  data: json.data,
});
export const updateOrderPricingFailure = (error) => ({
  type: UPDATE_ORDER_PRICING_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function fetchOrderChat(id) {
  return (dispatch) => {
    dispatch(fetchOrderChatBegin());
    return adminApi
      .fetchOrderChat(id)
      .then((json) => {
        dispatch(fetchOrderChatSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchOrderChatFailure(error));
      });
  };
}

export function fetchOrderSKU(id, {setLoading}) {
  if(setLoading)
    setLoading(true);

  return (dispatch) => {
    dispatch(fetchOrderSKUBegin());
    return adminApi
      .fetchOrderSKU(id)
      .then((json) => {
        dispatch(fetchOrderSKUSuccess(json));
        if(setLoading)
          setLoading(false);
        return json;
      })
      .catch((error) => {
        if(setLoading)
          setLoading(false);
        fetchOrderSKUFailure(error);
      });
  };
}

export function updateSerialNumbers(id, data) {
  return (dispatch) => {
    dispatch(updateSerialNumberBegin());
    return adminApi
      .updateSerialNumber(id, data)
      .then((json) => {
        dispatch(updateSerialNumbersSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateSerialNumbersFailure(error));
      });
  };
}

export const updateSerialNumberBegin = (json) => ({
  type: UPDATE_SERIAL_NUMBERS_BEGIN,
});
export const updateSerialNumbersSuccess = (json) => ({
  type: UPDATE_SERIAL_NUMBERS_SUCCESS,
  data: json.data.booking,
});
export const updateSerialNumbersFailure = (error) => ({
  type: UPDATE_SERIAL_NUMBERS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchAdminOrdersBegin = () =>
// the whole object is n action
({
  type: FETCH_ADMIN_ORDER_BEGIN,
});
export const fetchAdminOrdersSuccess = (json) => ({
  type: FETCH_ADMIN_ORDER_SUCCESS,
  data: json.data.bookings,
  pagination: json.data.pagination,
  filters: json.data.filters,
  actions: json.data.actions,
});
export const fetchAdminOrdersFiltersSuccess = (json) => ({
  type: FETCH_ADMIN_ORDER_FILTERS_SUCCESS,
  filters: json.data.filters,
});
export const fetchAdminOrdersFailure = (error) => ({
  type: FETCH_ADMIN_ORDER_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchOrderSummaryBegin = () =>
// the whole object is n action
({
  type: FETCH_ORDER_SUMMARY_BEGIN,
});
export const fetchOrderSummarySuccess = (json) => ({
  type: FETCH_ORDER_SUMMARY_SUCCESS,
  data: json.data,
});
export const fetchOrderSummaryFailure = (error) => ({
  type: FETCH_ORDER_SUMMARY_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchOrderHistoryBegin = () =>
// the whole object is n action
({
  type: FETCH_ORDER_HISTORY_BEGIN,
});
export const fetchOrderHistorySuccess = (json) => ({
  type: FETCH_ORDER_HISTORY_SUCCESS,
  data: json.data,
});
export const fetchOrderHistoryFailure = (error) => ({
  type: FETCH_ORDER_HISTORY_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchOrderSKUBegin = () => ({
  type: FETCH_ORDER_SKU_BEGIN,
});
export const fetchOrderSKUSuccess = (json) => ({
  type: FETCH_ORDER_SKU_SUCCESS,
  data: json.data,
});
export const fetchOrderSKUFailure = (error) => ({
  type: FETCH_ORDER_SKU_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchShippingOrderBegin = () => ({
  type: FETCH_SHIPPING_ORDER_BEGIN,
});

export const fetchShippingOrderSuccess = (json) => ({
  type: FETCH_SHIPPING_ORDER_SUCCESS,
  data: json.data,
});

export const fetchShippingOrderFailure = (error) => ({
  type: FETCH_SHIPPING_ORDER_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchOrderPricingBegin = () => ({
  type: FETCH_ORDER_PRICING_BEGIN,
});

export const fetchOrderPricingSuccess = (json) => ({
  type: FETCH_ORDER_PRICING_SUCCESS,
  data: json.data,
});
export const fetchOrderPricingFailure = (error) => ({
  type: FETCH_ORDER_PRICING_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const fetchOrderChatBegin = () => ({
  type: FETCH_ORDER_CHAT_BEGIN,
});
export const fetchOrderChatSuccess = (json) => ({
  type: FETCH_ORDER_CHAT_SUCCESS,
  data: json.data,
});
export const fetchOrderChatFailure = (error) => ({
  type: FETCH_ORDER_CHAT_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});
export const updateParcelMaterialFilter = (value) => ({
  type: UPDATE_PARCELMATERIAL_FILTER,
  data: value,
});

export function updateOrderNote(id, data) {
  return (dispatch) => {
    dispatch(updateOrderNoteBegin());
    let res = adminApi
      .updateOrderNote(id, data)
      .then((json) => {
        dispatch(updateOrderNoteSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateOrderNoteFailure(error));
      });
    return res;
  };
}

export const updateOrderNoteBegin = () => ({
  type: FETCH_ORDER_NOTE_BEGIN,
});

export function updateOrderNoteSuccess(json) {
  return {
    type: UPDATE_ORDER_NOTE_SUCCESS,
    payload: json.data.booking,
  };
}

export function updateOrderNoteFailure(error) {
  return {
    type: UPDATE_ORDER_NOTE_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function downloadAUCSV(param) {
  return (dispatch) => {
    dispatch(downloadAUCSVBegin()); //calling this action to show loading
    return adminApi
      .downloadAUCSV(param) // calling list of ORDERS
      .then((json) => {
        dispatch(downloadAUCSVSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(downloadAUCSVFailure(error));
      });
  };
}

export const downloadAUCSVBegin = () => ({
  type: DOWNLOAD_AU_CSV_BEGIN,
});

export function downloadAUCSVSuccess(json) {
  // const data = json.split(",");
  return {
    type: DOWNLOAD_AU_CSV_SUCCESS,
    payload: json,
  };
}

export function downloadAUCSVFailure(error) {
  return {
    type: DOWNLOAD_AU_CSV_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function orderBulkUpdate(param) {
  return (dispatch) => {
    dispatch(orderBulkUpdateBegin()); //calling this action to show loading
    return adminApi
      .orderBulkUpdate(param) // calling list of ORDERS
      .then((json) => {
        dispatch(orderBulkUpdateSuccess(json, param.bookings.status));
        return json;
      })
      .catch((error) => {
        dispatch(orderBulkUpdateFailure(error));
      });
  };
}

export function orderUpdateWarehouse(booking_id, warehouse_id) {
  return (dispatch) => {
    dispatch(orderWarehouseUpdateBegin()); //calling this action to show loading
    let params = {
      booking: {
        warehouse_id: warehouse_id,
      },
    };
    return adminApi
      .orderUpdateWarehouse(booking_id, params) // calling list of ORDERS
      .then((json) => {
        dispatch(orderWarehouseUpdateSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(orderWarehouseUpdateFailure(error));
      });
  };
}

export function orderProcessBulkUpdate(param) {
  return (dispatch) => {
    dispatch(orderBulkUpdateBegin()); //calling this action to show loading
    return adminApi
      .orderProcessBulkUpdate(param) // calling list of ORDERS
      .then((json) => {
        dispatch(orderBulkUpdateSuccess(json, param.bookings.status));
        return json;
      })
      .catch((error) => {
        dispatch(orderBulkUpdateFailure(error));
      });
  };
}

export const orderBulkUpdateBegin = () => ({
  type: ORDER_BULK_UPDATE_BEGIN,
});

export function orderBulkUpdateSuccess(json, status) {
  return {
    type: ORDER_BULK_UPDATE_SUCCESS,
    data: json.data,
    status: status,
  };
}

export function orderBulkUpdateFailure(error) {
  return {
    type: ORDER_BULK_UPDATE_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function createOrderChat(id, param) {
  return (dispatch) => {
    dispatch(createOrderChatBegin()); //calling this action to show loading
    return adminApi
      .createOrderChat(id, param) // calling list of ORDERS
      .then((json) => {
        dispatch(createOrderChatSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createOrderChatFailure(error));
      });
  };
}

export const createOrderChatBegin = () => ({
  type: ORDER_CHAT_CREATE_BEGIN,
});

export function createOrderChatSuccess(json) {
  // const data = json.split(",");
  return {
    type: ORDER_CHAT_CREATE_SUCCESS,
    payload: json.data,
  };
}

export function createOrderChatFailure(error) {
  return {
    type: ORDER_CHAT_CREATE_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function setOrderFilter(filters) {
  return {
    type: SET_ADMIN_ORDERS_FILTER,
    payload: { filters },
  };
}

export const clearOrderFilters = () => ({
  type: CLEAR_ADMIN_ORDERS_FILTER,
});

export function updateOrderShipment(id, param) {

  return (dispatch) => {
    const actionCallback = param?.actionCallback || function(){};
    dispatch(updateOrderShipmentBegin()); //calling this action to show loading
    if (param.booking.order_type == "Inbound") {
      return adminApi
        .updateInboundOrderShipment(id, param)
        .then((json) => {
          actionCallback(false)
          dispatch(updateOrderShipmentSuccess(json));
          return json;
        })
        .catch((error) => {
          actionCallback(false)
          dispatch(updateOrderShipmentFailure(error));
        });
    } else {
      return adminApi
        .updateOrderShipment(id, param)
        .then((json) => {
          actionCallback(false)
          dispatch(updateOrderShipmentSuccess(json));
          return json;
        })
        .catch((error) => {
          actionCallback(false)
          dispatch(updateOrderShipmentFailure(error));
        });
    }
  };
}

export const updateOrderShipmentBegin = () => ({
  type: UPDATE_ORDER_SHIPMENT_BEGIN,
});

export function updateOrderShipmentSuccess(json) {
  // const data = json.split(",");
  return {
    type: UPDATE_ORDER_SHIPMENT_SUCCESS,
    payload: json.data,
  };
}

export function updateOrderShipmentFailure(error) {
  return {
    type: UPDATE_ORDER_SHIPMENT_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function updateOrderSKUs(id, data) {
  return (dispatch) => {
    dispatch(updateOrderSKUsBegin());
    let res = adminApi
      .updateOrderSKUs(id, data)
      .then((json) => {
        dispatch(updateOrderSKUsSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateOrderSKUsFailure(error));
      });
    return res;
  };
}

export const updateOrderSKUsBegin = () => ({
  type: UPDATE_ORDER_SKUS_BEGIN,
});

export function updateOrderSKUsSuccess(json) {
  return {
    type: UPDATE_ORDER_SKUS_SUCCESS,
    payload: json.data.booking,
  };
}

export function updateOrderSKUsFailure(error) {
  return {
    type: UPDATE_ORDER_SKUS_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function addInternalComment(id, param) {
  return (dispatch) => {
    dispatch(addInternalCommentBegin()); //calling this action to show loading
    return adminApi
      .addInternalComment(id, param) // calling list of ORDERS
      .then((json) => {
        dispatch(addInternalCommentSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(addInternalCommentFailure(error));
      });
  };
}

export const addInternalCommentBegin = () => ({
  type: INTERNAL_COMMENT_CREATE_BEGIN,
});

export function addInternalCommentSuccess(json) {
  // const data = json.split(",");
  return {
    type: INTERNAL_COMMENT_CREATE_SUCCESS,
    payload: json.data,
  };
}

export function addInternalCommentFailure(error) {
  return {
    type: INTERNAL_COMMENT_CREATE_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function fetchOrgOrders(id, param, withoutLoading) {
  return (dispatch) => {
    if (!withoutLoading) dispatch(fetchOrgOrdersBegin()); //calling this action to show loading
    return adminApi
      .fetchOrgOrders(id, param) // calling list of ORDERS
      .then((json) => {
        dispatch(fetchOrgOrdersSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchOrgOrdersFailure(error));
      });
  };
}

export const fetchOrgOrdersBegin = () =>
// the whole object is n action
  ({
    type: FETCH_ORG_ORDER_BEGIN,
  });

export const fetchOrgOrdersSuccess = (json) => ({
  type: FETCH_ORG_ORDER_SUCCESS,
  data: json.data.orders,
  pagination: json.data.pagination,
  filters: json.data.filters,
  actions: json.data.actions,
  organisation: json.data.organisation,
});

export const fetchOrgOrdersFailure = (error) => ({
  type: FETCH_ORG_ORDER_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function downloadOrderTmpl(id) {
  return (dispatch) => {
    dispatch(downloadOrderTmplBegin());
    return adminApi
      .downloadOrderTmpl(id)
      .then((res) => {
        dispatch(downloadOrderTmplSuccess(res));
        return res;
      })
      .catch((error) => {
        dispatch(downloadOrderTmplFailure(error));
      });
  };
}

export const downloadOrderTmplBegin = () => ({
  type: DOWNLOAD_ORDER_TMPL_BEGIN,
});

export const downloadOrderTmplSuccess = (res) => ({
  type: DOWNLOAD_ORDER_TMPL_SUCCESS,
  payload: {
    template: res,
  },
});

export const downloadOrderTmplFailure = (error) => ({
  type: DOWNLOAD_ORDER_TMPL_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function uploadOrderTmpl(id, params) {
  return (dispatch) => {
    dispatch(uploadOrderTmplBegin());
    return adminApi
      .uploadOrderTmpl(id, params)
      .then((json) => {
        dispatch(uploadOrderTmplSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(uploadOrderTmplFailure(error));
      });
  };
}

export const uploadOrderTmplBegin = () => ({
  type: UPLOAD_ORDER_TMPL_BEGIN,
});

export const uploadOrderTmplSuccess = (json) => ({
  type: UPLOAD_ORDER_TMPL_SUCCESS,
  payload: {
    template: json.data,
  },
});

export const uploadOrderTmplFailure = (error) => ({
  type: UPLOAD_ORDER_TMPL_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export const clearTmpls = () => ({
  type: CLEAR_TMPLS,
});

export const clearOrders = () => ({
  type: CLEAR_ADMIN_ORDERS,
});

export const createOrder = (id, data) => {
  return (dispatch) => {
    dispatch(createOrderBegin()); //calling this action to show loading
    return adminApi
      .createOrder(id, data) // calling list of ORDERS
      .then((json) => {
        dispatch(createOrderSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(createOrderFailure(error));
      });
  };
};

export const createOrderBegin = () => ({
  type: ORDER_CREATE_BEGIN,
});

export function createOrderSuccess(json) {
  return {
    type: ORDER_CREATE_SUCCESS,
    payload: json.data,
  };
}

export function createOrderFailure(error) {
  return {
    type: ORDER_CREATE_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function selectCarrier(bookingId, data) {
  return (dispatch) => {
    dispatch(selectCarrierBegin()); //calling this action to show loading
    return adminApi
      .selectCarrier(bookingId, data)
      .then((json) => {
        dispatch(selectCarrierSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(selectCarrierFailure(error));
        return error;
      });
  };
}

export const selectCarrierBegin = () => ({
  type: SELECT_CARRIER_BEGIN,
});

export function selectCarrierSuccess(json) {
  // const data = json.split(",");
  return {
    type: SELECT_CARRIER_SUCCESS,
    payload: json.data,
  };
}

export function selectCarrierFailure(error) {
  return {
    type: SELECT_CARRIER_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function cancelLabel(bookingId) {
  return (dispatch) => {
    dispatch(cancelLabelBegin());
    return adminApi
      .cancelLabel(bookingId)
      .then((json) => {
        dispatch(cancelLabelSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(cancelLabelFailure(error));
      });
  };
}

export const cancelLabelBegin = () => ({
  type: CANCEL_LABEL_BEGIN,
});

export function cancelLabelSuccess(json) {
  // const data = json.split(",");
  return {
    type: CANCEL_LABEL_SUCCESS,
    payload: json.data,
  };
}

export function cancelLabelFailure(error) {
  return {
    type: CANCEL_LABEL_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function removeCarrier(bookingId) {
  return (dispatch) => {
    dispatch(removeCarrierBegin());
    return adminApi
      .removeCarrier(bookingId)
      .then((json) => {
        dispatch(removeCarrierSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(removeCarrierFailure(error));
      });
  };
}

export const removeCarrierBegin = () => ({
  type: REMOVE_CARRIER_BEGIN,
});

export function removeCarrierSuccess(json) {
  // const data = json.split(",");
  return {
    type: REMOVE_CARRIER_SUCCESS,
    payload: json.data,
  };
}

export function removeCarrierFailure(error) {
  return {
    type: REMOVE_CARRIER_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function createCustomCarrier(bookingId, data) {
  return (dispatch) => {
    dispatch(createCustomCarrierBegin());
    /* Action callback for components consuming this action  */
    const callBackFunction =  data?.addNewCarrierCallback || function(){};
    return adminApi
      .createCustomCarrier(bookingId, data)
      .then((json) => {
        dispatch(createCustomCarrierSuccess(json));
        callBackFunction(true)
        return json;
      })
      .catch((error) => {
        dispatch(createCustomCarrierFailure(error));
        callBackFunction(false)
      });
  };
}

export const createCustomCarrierBegin = () => ({
  type: CREATE_CUSTOM_CARRIER_BEGIN,
});

export function createCustomCarrierSuccess(json) {
  // const data = json.split(",");
  return {
    type: CREATE_CUSTOM_CARRIER_SUCCESS,
    payload: json.data,
  };
}

export function createCustomCarrierFailure(error) {
  return {
    type: CREATE_CUSTOM_CARRIER_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function viewShippingLabel(bookingId, data) {
  return (dispatch) => {
    dispatch(viewShippingLabelBegin());
    return adminApi
      .viewShippingLabel(bookingId, data)
      .then((json) => {
        dispatch(viewShippingLabelSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(viewShippingLabelFailure(error));
      });
  };
}

export const viewShippingLabelBegin = () => ({
  type: VIEW_CARRIER_BEGIN,
});

export function viewShippingLabelSuccess(json) {
  // const data = json.split(",");
  return {
    type: VIEW_CARRIER_SUCCESS,
    payload: json.data,
  };
}

export function viewShippingLabelFailure(error) {
  return {
    type: VIEW_CARRIER_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function printShippingLabel(bookingId, data, slug, is_internal_label) {
  return (dispatch) => {
    dispatch(printShippingLabelBegin());
    return adminApi.printLabel(bookingId, data, slug, is_internal_label).then((json) => {
      dispatch(printShippingLabelSuccess(json, slug));
      return json;
    })
      .catch((error) => {
        dispatch(printShippingLabelFailure(error));
      });
  };
}

export const printShippingLabelBegin = () => ({
  type: PRINT_SHIPPING_LABEL_BEGIN,
});

export function printShippingLabelSuccess(json, type) {
  // const data = json.split(",");
  return {
    type: PRINT_SHIPPING_LABEL_SUCCESS,
    payload: json,
    labelType: type,
  };
}

export function printShippingLabelFailure(error) {
  return {
    type: PRINT_SHIPPING_LABEL_FAILURE,
    payload: {
      error: error.response?.data?.error_message
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

export function unProcessOrder(id) {
  return (dispatch) => {
    dispatch(unProcessOrderBegin());
    return adminApi
      .unProcessOrder(id)
      .then((json) => {
        dispatch(unProcessOrderSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(unProcessOrderFailure(error));
      });
  };
}

export const unProcessOrderBegin = () => ({
  type: UNPROCESS_ORDER_BEGIN,
});
export const unProcessOrderSuccess = (json) => ({
  type: UNPROCESS_ORDER_SUCCESS,
  data: json.data,
});
export const unProcessOrderFailure = (error) => ({
  type: UNPROCESS_ORDER_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function getOrderParcels(id) {
  return (dispatch) => {
    dispatch(getOrderParcelsBegin());
    return adminApi
      .getOrderParcels(id)
      .then((json) => {
        dispatch(getOrderParcelsSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(getOrderParcelsFailure(error));
      });
  };
}

export const getOrderParcelsBegin = () => ({
  type: GET_ORDER_PARCELS_BEGIN,
});
export const getOrderParcelsSuccess = (json) => ({
  type: GET_ORDER_PARCELS_SUCCESS,
  data: json.data,
});
export const getOrderParcelsFailure = (error) => ({
  type: GET_ORDER_PARCELS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function updateOrderParcels(id, data) {
  return (dispatch) => {
    dispatch(updateOrderParcelsBegin());
    return adminApi
      .updateOrderParcels(id, data)
      .then((json) => {
        dispatch(updateOrderParcelsSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(updateOrderParcelsFailure(error));
      });
  };
}

export const updateOrderParcelsBegin = () => ({
  type: UPDATE_ORDER_PARCELS_BEGIN,
});
export const updateOrderParcelsSuccess = (json) => ({
  type: UPDATE_ORDER_PARCELS_SUCCESS,
  data: json.data,
});
export const updateOrderParcelsFailure = (error) => ({
  type: UPDATE_ORDER_PARCELS_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function suggestHandlings(id, data) {
  return (dispatch) => {
    dispatch(suggestHandlingsBegin());

    return adminApi
      .suggestHandlings(id, data)
      .then((json) => {
        if(!!json) dispatch(suggestHandlingsSuccess(json));
        return json || null;

      })
      .catch((error) => {
        dispatch(suggestHandlingsFailure(error));
      });
  };
}

export const suggestHandlingsBegin = () => ({
  type: SUGGEST_HANDLING_BEGIN,
});
export const suggestHandlingsSuccess = (json) => ({
  type: SUGGEST_HANDLING_SUCCESS,
  data: json.data,
});
export const suggestHandlingsFailure = (error) => ({
  type: SUGGEST_HANDLING_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function getBookingItemDetail(id, itemId) {
  return (dispatch) => {
    dispatch(getBookingItemDetailBegin());
    return adminApi
      .getBookingItemDetail(id, itemId)
      .then((json) => {
        dispatch(getBookingItemDetailSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(getBookingItemDetailFailure(error));
      });
  };
}

export const getBookingItemDetailBegin = () => ({
  type: BOOKING_ITEM_DETAIL_BEGIN,
});
export const getBookingItemDetailSuccess = (json) => ({
  type: BOOKING_ITEM_DETAIL_SUCCESS,
  data: json.data,
});
export const getBookingItemDetailFailure = (error) => ({
  type: BOOKING_ITEM_DETAIL_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function validateAddress(details) {
  return (dispatch) => {
    return adminApi
      .validateAddress(details)
      .then((json) => {
        return { success: true, loading: false, error: null, data: json.data };
      })
      .catch((error) => {
        return {
          success: false,
          loading: false,
          error: error.reponse
            ? error.response.data
            : { error_message: "Something went wrong" },
          data: null,
        };
      });
  };
}

export function fetchBatchDetail(id) {
  return (dispatch) => {
    dispatch(fetchBatchDetailBegin()); //calling this action to show loading
    return adminApi
      .fetchBatchDetail(id) // calling list of ORDERS
      .then((json) => {
        dispatch(fetchBatchDetailSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchBatchDetailFailure(error));
      });
  };
}

export const fetchBatchDetailBegin = () =>
// the whole object is n action
({
  type: FETCH_BATCH_DETAIL_BEGIN,
});
export const fetchBatchDetailSuccess = (json) => ({
  type: FETCH_BATCH_DETAIL_SUCCESS,
  data: json.data.batch_details,
});
export const fetchBatchDetailFailure = (error) => ({
  type: FETCH_BATCH_DETAIL_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function fetchSuggestedCarriers(id) {
  return (dispatch) => {
    dispatch(fetchSuggestedCarriersBegin()); //calling this action to show loading
    return adminApi
      .fetchSuggestedCarriers(id) // calling list of ORDERS
      .then((json) => {
        dispatch(fetchSuggestedCarriersSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchSuggestedCarriersFailure(error));
      });
  };
}

export const fetchSuggestedCarriersBegin = () => ({
  type: FETCH_SUGGESTED_CARRIER_BEGIN,
});

export const fetchSuggestedCarriersSuccess = (json) => ({
  type: FETCH_SUGGESTED_CARRIER_SUCCESS,
  data: json.data,
});

export const fetchSuggestedCarriersFailure = (error) => ({
  type: FETCH_SUGGESTED_CARRIER_FAILURE,
  payload: {
    error: error.response
      ? error.response.data
      : "Something went wrong. Please try again",
  },
});

export function archiveComment(id, param) {
  return (dispatch) => {
    dispatch(archiveCommentBegin());
    return adminApi
      .archiveComment(id, param)
      .then((json) => {
        dispatch(archiveCommentSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(archiveCommentFailure(error));
      });
  };
}

export const archiveCommentBegin = () => ({
  type: ARCHIVE_COMMENT_BEGIN,
});

export function archiveCommentSuccess(json) {
  return {
    type: ARCHIVE_COMMENT_SUCCESS,
    payload: json.data,
  };
}

export function archiveCommentFailure(error) {
  return {
    type: ARCHIVE_COMMENT_FAILURE,
    payload: {
      error: error.response
        ? error.response.data
        : "Something went wrong. Please try again",
    },
  };
}

const orderWarehouseUpdateBegin = () => ({
  type: ORDER_WAREHOUSE_UPDATE_BEGIN,
});

function orderWarehouseUpdateSuccess(json) {
  return {
    type: ORDER_WAREHOUSE_UPDATE_SUCCESS,
    data: json.data,
  };
}

function orderWarehouseUpdateFailure(error) {
  return {
    type: ORDER_WAREHOUSE_UPDATE_FAILURE,
    payload: {
      error: error?.response?.data || "Something went wrong. Please try again",
    },
  };
}

export function fetchInboundLabel(id, param) {
  return (dispatch) => {
    dispatch(fetchInboundLabelBegin());
    return adminApi
      .fetchInboundLabel(id, param)
      .then((json) => {
        dispatch(fetchInboundLabelSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchInboundLabelFailure(error));
      });
  };
}

export const fetchInboundLabelBegin = () => ({
  type: FETCH_INBOUND_LABEL_BEGIN,
});

export function fetchInboundLabelSuccess(json) {
  return {
    type: FETCH_INBOUND_LABEL_SUCCESS,
    payload: json.data,
  };
}

export function fetchInboundLabelFailure(json) {
  return {
    type: FETCH_INBOUND_LABEL_FAILURE,
    payload: json.response.data.error_message,
  };
}

export function fetchInboundBoxes(id, param) {
  return (dispatch) => {
    dispatch(fetchInboundBoxesBegin());
    return adminApi
      .fetchInboundBoxes(id, param)
      .then((json) => {
        dispatch(fetchInboundBoxesSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchInboundBoxesFailure(error));
      });
  };
}

export const fetchInboundBoxesBegin = () => ({
  type: FETCH_INBOUND_BOXES_BEGIN,
});

export function fetchInboundBoxesSuccess(json) {
  return {
    type: FETCH_INBOUND_BOXES_SUCCESS,
    payload: json.data,
  };
}

export function fetchInboundBoxesFailure(json) {
  return {
    type: FETCH_INBOUND_BOXES_FAILURE,
    payload: json.response.data.error_message,
  };
}

export function fetchInboundReport(id, param) {
  return (dispatch) => {
    dispatch(fetchInboundReportBegin());
    return adminApi
      .fetchInboundReport(id, param)
      .then((json) => {
        dispatch(fetchInboundReportSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchInboundReportFailure(error));
      });
  };
}

export const fetchInboundReportBegin = () => ({
  type: FETCH_INBOUND_REPORT_BEGIN,
});

export function fetchInboundReportSuccess(json) {
  return {
    type: FETCH_INBOUND_REPORT_SUCCESS,
    payload: json.data,
  };
}

export function fetchInboundReportFailure(json) {
  return {
    type: FETCH_INBOUND_REPORT_FAILURE,
    payload: json.response.data.error_message,
  };
}

export function printInboundShippingLabel(id, param) {
  return adminApi.printInboundLabel(id, param);
}

export const printInboundShippingLabelBegin = () => ({
  type: PRINT_INBOUND_SHIPPING_LABEL_BEGIN,
});

export function printInboundShippingLabelSuccess(json) {
  return {
    type: PRINT_INBOUND_SHIPPING_LABEL_SUCCESS,
    payload: json.data,
  };
}

export function printInboundShippingLabelFailure(json) {
  return {
    type: PRINT_INBOUND_SHIPPING_LABEL_FAILURE,
    payload: json.data,
  };
}
